


































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { ProductSpecification } from '../../../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<BoxSection>({
  name: 'BoxSection'
})
export class BoxSection extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: true, type: Object })
  public box!: ProductSpecification['boxSection']

  @Prop({ required: false, type: String })
  public ean!: string

  @Prop({ required: false, type: String })
  public sku!: string
}

export default BoxSection
