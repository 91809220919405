





import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ProductSpecification } from '../../../front/products/organisms/ProductSpecification'

import {
  ProductSpecificationColors
} from '../../../front/products/organisms/ProductSpecification/ProductSpecification.contracts'

import { ProductSpecificationModule } from '../ProductSpecification.contracts'
import { AttributeValue } from '../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ProductSpecificationModuleUi>({
  name: 'ProductSpecificationModuleUi',
  components: { ProductSpecification }
})
export class ProductSpecificationModuleUi extends AbstractModuleUi<ProductSpecificationModule> {
  public get defaultColor (): ProductSpecificationColors {
    return {
      content: '#000000',
      heading: '#000000',
      navTabs: '#000000',
      subHeading: '#D29A7E',
      background: '#F5F5F5',
      backgroundBox: '#FFFFFF',
      boxSectionContentTitle: '#00000099',
      stepSectionBoxShadow: '#FFFFFF',
      stepSectionStepBackground: '#000000',
      stepSectionStepColor: '#FFF'
    }
  }

  public get shouldRender (): boolean {
    return this.hasContent && this.hasCategory && this.hasSpecification
  }

  public get hasCategory (): boolean {
    if (!this.hasContent) {
      return false
    }

    if (!this.data.content.product) {
      return false
    }

    return typeof this.data.content.product.category !== 'undefined'
  }

  public get hasSpecification (): boolean {
    if (!this.data.content.product) {
      return false
    }
    return this.data.content.product.specification !== null
  }

  public get color (): ProductSpecificationColors {
    let candidate
    if (this.content.color) {
      candidate = this.content.color
    } else if (this.content.product?.category.specificationColors) {
      candidate = this.content.product.category.specificationColors
    }

    if (typeof candidate !== 'object' || candidate === null || Array.isArray(candidate)) {
      return this.defaultColor
    }

    return candidate
  }

  public getEan (): AttributeValue {
    if (!this.hasMoreThanOneVariant && !this.$route.query.variant) {
      return String(this.data.content.product?.variants[String('_')].attributes?.ean)
    } else if (this.hasMoreThanOneVariant && this.$route.query.variant) {
      return String(this.data.content.product?.variants[String(this.$route.query.variant)].attributes?.ean)
    }

    return ''
  }

  public getSku (): AttributeValue {
    if (!this.hasMoreThanOneVariant && !this.$route.query.variant) {
      return String(this.data.content.product?.variants[String('_')].sku)
    } else if (this.hasMoreThanOneVariant && this.$route.query.variant) {
      return String(this.data.content.product?.variants[String(this.$route.query.variant)].sku)
    }

    return ''
  }

  public get hasMoreThanOneVariant (): boolean {
    if (!this.data.content.product) {
      return false
    }

    const variants = Array(this.data.content.product?.variants)

    return Array.isArray(variants) && Object.keys(this.data.content.product.variants).length > 1
  }
}

export default ProductSpecificationModuleUi
